










import { Component, Vue } from 'vue-property-decorator'
import { rootStore } from '@/store'

@Component({
  components: {
    MainLoader: () => import('@/components/UI/MainLoader.vue'),
    Modals: () => import('@/components/Modals/index.vue'),
  },
})
export default class TheLogin extends Vue {
  get isShowMainLoader (): boolean {
    return !rootStore.state.isAppReady
  }
}
